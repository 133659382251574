import React, {
  useCallback,
  ReactElement,
} from 'react';
import { CellProps } from 'react-table';
import { useSelector } from 'react-redux';
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { Link, useHistory } from 'react-router-dom';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import PageSizePicker from 'components/PageSizePicker';
import { useSmsAutoReplyQuery } from 'components/hooks/useSmsAutoReplyQuery';
import { ISmsAutoReplyModel } from 'types/ISmsAutoReplies';
import { ReactComponent as Plus } from 'styles/images/plus.svg';
import * as InboxApi from 'api/InboxAPI';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';
import { RootState } from 'types/rootState';
import { formatPhoneNumberString } from 'lib/formatters/phoneNumber';
import SmsAutoReplyFilter, { ISearchQuery } from './components/SmsAutoReplyFilter';
import DeleteActionButton from './components/DeleteActionButton';

function SmsAutoReplyIndexPage(): ReactElement {
  const translateUserType = useUserTypeTranslation();
  const columns = [
    {
      Header: 'Keywords',
      accessor: 'Keyword',
      sortable: true,
      Cell: (props: CellProps<ISmsAutoReplyModel>): string => {
        const {
          row: { original },
        } = props;
        return original.Keyword.join(', ');
      },
    },
    {
      Header: 'Description',
      accessor: 'Description',
      sortable: true,
      showToolTip: true,
      Cell: (props: CellProps<ISmsAutoReplyModel>): string => {
        const {
          row: { original },
        } = props;
        return original.Description.length > 30 ? `${original.Description.substring(0, 30)}...` : original.Description;
      },
    },
    {
      Header: 'Response',
      accessor: 'Response',
      sortable: true,
      showToolTip: true,
      Cell: (props: CellProps<ISmsAutoReplyModel>): string => {
        const {
          row: { original },
        } = props;
        return original.Response.length > 30 ? `${original.Response.substring(0, 30)}...` : original.Response;
      },
    },
    {
      Header: 'Visibility',
      accessor: 'Visibility',
      Cell: (props: CellProps<ISmsAutoReplyModel>): string => {
        const {
          row: { original },
        } = props;
        const visibility = [];
        if (original.StaffVisible) {
          visibility.push(translateUserType('Staff'));
        }
        if (original.SeniorVisible) {
          visibility.push(translateUserType('Senior'));
        }
        if (original.FamilyVisible) {
          visibility.push(translateUserType('Family'));
        }
        return visibility.join(', ');
      },
    },
    {
      Header: 'Active',
      accessor: 'IsActive',
      sortable: true,
      Cell: (props: CellProps<ISmsAutoReplyModel>): string => {
        const {
          row: { original },
        } = props;
        return original.IsActive ? 'Yes' : 'No';
      },
    },
  ];

  const history = useHistory();
  const [pageParam = 1, setPageParam] = useQueryParam('page', NumberParam);
  const [perPageParam = 10, setPerPageParam] = useQueryParam('perpage', NumberParam);
  const [sortFieldParam, setSortFieldParam] = useQueryParam('sortField', StringParam);
  const [sortDirectionParam, setSortDirectionParam] = useQueryParam('sortDirection', StringParam);

  const [searchField, setSearchField] = useQueryParam('searchField', StringParam);
  const [searchValue, setSearchValue] = useQueryParam('searchValue', StringParam);

  const resetFilters = (): void => {
    setSearchField('', 'pushIn');
    setSearchValue('', 'pushIn');
    setPageParam(1, 'pushIn');
  };

  const accountSenderNumber = useSelector(
    (state: RootState) => state.UserInfo?.userInfo?.AccountDetail?.AccountSenderNumber);

  const { data, mutate, isLoading } = useSmsAutoReplyQuery();
  const items = data?.Data ?? [];
  console.log('data', data);

  const getSearchQuery = useCallback((): ISearchQuery => {
    const query = { searchField, searchValue };
    if (!query.searchValue) return {};
    return { searchField: query.searchField, searchValue: query.searchValue };
  }, [searchField, searchValue]);

  const onSearch = (query: ISearchQuery): void => {
    if (query.searchValue) {
      setSearchField(query.searchField);
      setSearchValue(query.searchValue.trim());
    } else {
      setSearchField(query.searchField);
      setSearchValue('');
    }
    setPageParam(1, 'pushIn');
  };

  const deleteSmsAutoReply = async (replyId: string) => {
    await InboxApi.deleteSmsAutoReply(replyId);
  }

  const rowActionItems = (entityId: string): ReactElement => (
    <>
      <Link to={`/sms-auto-reply/${entityId}/edit`}>Edit</Link>
      {' '}
      <DeleteActionButton
        smsAutoReplyId={entityId}
        onSuccess={() => mutate()}
        onDelete={() => deleteSmsAutoReply(entityId)}
      />
    </>
  );

  const renderIndexFilter = (): ReactElement | null => (
    <SmsAutoReplyFilter
      searchQuery={getSearchQuery()}
      onSearch={onSearch}
      onResetFilters={resetFilters}
      totalItems={data?.Pagination.TotalItems}
      itemsLoading={isLoading}
    />
  );

  return (
    <div className="sms-auto-reply">
      <h1 className="mb-4">SMS Auto-Reply Configuration</h1>
      <p>SMS Auto-replies are a way to configure automatic responses to incoming SMS messages your account receives that contain certain keywords. This can be used by members of your community to easily access information you control on an on-demand basis.</p>
      <p>
        Users can see a list of all available keyword options and their descriptions by texting &quot;options&quot;, &quot;menu&quot; or &quot;guide&quot; to&nbsp;
        {formatPhoneNumberString(accountSenderNumber)}
        .
      </p>
      <div className="header-bar mb-4">
        <div className="actions">
          <button
            role="link"
            type="button"
            onClick={() => history.push('/sms-auto-reply/create')}
            className="btn btn-lg btn-warning"
          >
            <Plus
              width="24"
              height="24"
              fill="currentColor"
              style={{ marginRight: '10px' }}
            />
            Add New Auto-Reply
          </button>
        </div>
      </div>

      {renderIndexFilter()}
      <div>
        <Table
          columns={columns}
          data={items}
          rowIDAccessor="SmsAutoReplyId"
          rowActionItems={rowActionItems}
          enableCheck={false}
          onRowClick={(id: string) => {
            history.push(`/sms-auto-reply/${id}/edit`);
          }}
          isLoading={isLoading}
          onColumnSort={(fieldName, direction) => {
            if (fieldName.length > 0) {
              setSortFieldParam(fieldName);
              setSortDirectionParam(direction);
            } else {
              setSortFieldParam('');
              setSortDirectionParam('');
            }
          }}
          defaultSortedColumn={sortFieldParam}
          defaultSortedColumnDirection={sortDirectionParam}
        />
        <div className="paging">
          <PageSizePicker
            initialPageSize={perPageParam}
            onSetPageSize={(size) => {
              setPageParam(1, 'replaceIn');
              setPerPageParam(size);
            }}
          />

          <Pagination
            currentPage={pageParam}
            totalPages={data?.Pagination?.TotalPages ?? 1}
            onPageChange={(newPage: number) => setPageParam(newPage, 'pushIn')}
            pageDelta={5}
          />
        </div>
      </div>
    </div>
  );
}
export default SmsAutoReplyIndexPage;
