import React, {
  useEffect,
  useState,
  ReactElement,
  useMemo,
  useCallback,
} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Control, useForm, FormProvider } from 'react-hook-form';
import { useQueryParam, StringParam } from 'use-query-params';
import {
  Link, useHistory,
  Prompt,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState } from 'types/rootState';
import { IGroup } from 'types/IGroup';
import { getGroupsMembers } from 'reducers/Groups';
import {
  Alert, Modal, Col, Row, Container, Button,
} from 'react-bootstrap';
import { IAccountIntegration, ILanguage, IUserCustomField } from 'reducers/IUserInfoState';
import _join from 'lodash/join';
import { toast } from 'react-toastify';
import { DeleteActionModal } from 'components/DeleteActionModal';
import smoothscroll from 'smoothscroll-polyfill';
import SubmitButton from 'components/FormControls/SubmitButton'
import { useIsSyncedCustomer } from 'hooks/useIsSyncedCustomer';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';
import _capitalize from 'lodash/capitalize';
import { IFormData } from 'types/IProfileForm';
import {
  Input, Select, TimeInput, FieldMap,
} from '../../../components/FormControls';
import Checkbox from '../../../components/Inputs/Checkbox';
import {
  profilesAddFamilyStarted, getProfileFamiliesByCpids,
  getProfilesIndex, putProfileRelationships, profilesAddFamilySuccess,
} from '../../../reducers/Profiles';
import {
  selectComplianceDateFieldNames,
  selectLifeDateFieldNames, getAccountIntegrations,
} from '../../../reducers/UserInfo';
import { LanguageNames, US_STATES } from '../../../lib/CommonSelect';
import { IOptions } from '../../../components/Inputs/Select';
import AssociationModal from './AssociationModal';
import GroupManager from './GroupManager';
import { UserType } from '../../../reducers/IProfile';
import { InactivePeriodDates } from './InactivePeriodDates';

export type { IFormData }

interface IProps {
  data?: Partial<IFormData>
  onSubmit: (data: IFormData) => void
  saving: boolean
}
interface ICustomFieldsProps {
  accountCustomFields: IUserCustomField[]
  data: Partial<IFormData>
  control: Control<IFormData>
  userType: string
}

const formatCustomFieldLabel = (input: string): string => {
  if (!input) {
    return '';
  }
  return input.split('#')[0].trim(); // Split at "#" and take the first part, then trim any extra spaces
};

const isFieldPresentForUserType = (userType: string, field: IUserCustomField) => {
  if (userType?.toLowerCase() === 'staff') return field.OnStaff;
  if (userType?.toLowerCase() === 'member') return field.OnResident;
  if (userType?.toLowerCase() === 'family') return field.OnFamily;
  return false;
}

const validateDateValueCustomFields = ['start date#v1sync', 'end date#v1sync'];
const dateRegEx = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

interface CustomFieldError {
  fieldName: string
  fieldError: string
}

function CustomFields({
  accountCustomFields, data, control, userType,
}: ICustomFieldsProps): ReactElement {
  const [customFieldInError, setCustomFieldInError] = useState<CustomFieldError[]>([]);
  const putCustomFieldInError = useCallback((fieldName: string, fieldError: string) => {
    const fd = customFieldInError.find((c) => c.fieldName === fieldName);
    if (!fd) {
      const newCustomFieldInError = [...customFieldInError, { fieldName, fieldError }]; // Avoid mutating state directly
      setCustomFieldInError(newCustomFieldInError);
    }
  }, [customFieldInError]);

  const removeCustomFieldInError = useCallback((fieldName: string) => {
    const fd = customFieldInError.find((c) => c.fieldName === fieldName);
    if (fd) {
      const newCustomFieldInError = customFieldInError.filter((n) => n.fieldName !== fieldName); // Filter to remove the field
      setCustomFieldInError(newCustomFieldInError);
    }
  }, [customFieldInError]);

  const customFields = useMemo(() => accountCustomFields
    ?.filter((field) => isFieldPresentForUserType(userType, field))
    ?.map((field) => (
      <div className="row" key={field.FieldName}>
        <div className="col col-md-6">
          <Input
            id={`CustomFields.${field.FieldName}`}
            type="text"
            name={`CustomFields.${field.FieldName}`}
            label={formatCustomFieldLabel(field.FieldName)}
            control={control}
            disabled={field.IsReadonly}
            errors={customFieldInError.find((c) => c.fieldName === field.FieldName) && customFieldInError.find((c) => c.fieldName === field.FieldName)?.fieldError}
            rules={{
              validate: (val: any) => {
                if (val && val.length > 30) {
                  putCustomFieldInError(field.FieldName, `${field.FieldName} value is too long. max 30 characters.`);
                  return false;
                }
                // if the custom field is start date#v1sync or 'end date#v1syn
                // validate the value is date or not
                if (val && validateDateValueCustomFields.indexOf(field.FieldName.toLowerCase()) > -1) {
                  if (!dateRegEx.test(val)) {
                    putCustomFieldInError(field.FieldName, `${field.FieldName.split('#')[0].trim()} must be in MM/dd/yyyy format.`);
                    return false;
                  }
                }
                removeCustomFieldInError(field.FieldName)
                return true;
              },
            }}
          />
        </div>
      </div>
    )) ?? [],
  [accountCustomFields, control, customFieldInError, putCustomFieldInError, removeCustomFieldInError, userType]);

  if (!accountCustomFields) {
    return null;
  }

  return (
    <>
      {' '}
      {customFields}
    </>
  )
}

function Form({ data, onSubmit, saving }: IProps): ReactElement {
  const formMethods = useForm<IFormData>({ mode: 'onChange' });
  const {
    register, control, reset, errors, watch, handleSubmit, formState,
  } = formMethods;

  useEffect(() => {
    if (data) { reset(data); }
  }, [reset, data]);

  const [associationModalOpen, setAssociationModalOpen] = useState<boolean>(false);
  const [associatedGroups, setAssociatedGroups] = useState<number[]>(data?.Groups || []);
  const [initialUserType] = useQueryParam('UserType', StringParam);
  const groupsById: { [key: string]: IGroup } = useSelector(
    (state: RootState) => state.Groups.byIds,
  );
  const allProfilesByIds = useSelector((state: RootState) => state.Profiles.byIds)
  const allprofileIds = useSelector((state: RootState) => state.Profiles.allIds)
  const filteredFamilyIds = allprofileIds?.filter(
    (cpid) => allProfilesByIds[cpid]?.UserType === UserType.Family && !data?.RelatedProfiles?.includes(cpid),
  );
  const familyProfiles = filteredFamilyIds.map((cpid) => allProfilesByIds[cpid]);
  const familyGroup = Object.fromEntries(Object.entries(groupsById).filter(
    ([k, v]: any) => v.Name === 'Family',
  ));
  const familyGroupId = Object.keys(familyGroup)[0];
  const [showModal, setShowModal] = useState<boolean>(false);
  const [familyIdToDelete, setFamilyIdToDelete] = useState<string>(undefined);
  const [interactionDisabled] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isBlocking, setIsBlocking] = useState(false);
  const profilesLoading = useSelector((state: RootState) => state.Profiles.loading);
  const isNew = !data?.CustomerProfileID || data?.CustomerProfileID.length === 0;
  const messageObj = {
    title: 'Profile Changes',
    messageText: 'You have unsaved changes, are you sure you want to leave?',
  };
  const phoneRegEx = /^\(?[2-9][0-9]{2}\)?\s?[2-9][0-9]{2}-?[0-9]{4}$/;
  const placeHolder = '(___) ___-____';
  const accountCustomFields = useSelector((state: RootState) => state.UserInfo.userInfo?.CustomFields);
  const isSyncedCustomer = useIsSyncedCustomer();
  const caremergeUserID = data?.CaremergeUserId ?? 0;

  useEffect(() => {
    if (Object.keys(formState.dirtyFields).length > 0) {
      setIsBlocking(true);
    } else {
      setIsBlocking(false)
    }
    smoothscroll.polyfill();
  }, [formState]);

  useEffect(() => {
    if (data?.Groups && associatedGroups.length === 0) {
      setAssociatedGroups(data.Groups);
    }
  }, [data, associatedGroups]);
  useEffect(() => {
    if (!(familyGroupId === null || familyGroupId === undefined)) {
      dispatch(getGroupsMembers(Number(familyGroupId)));
    }
  }, [dispatch, familyGroupId]);

  useEffect(() => {
    const params = { filterGroup: 'family', perpage: 1000 };
    dispatch(getProfilesIndex(params));
  }, [dispatch]);

  useEffect(() => {
    const errFields = [];
    for (const k in errors) {
      if (k) {
        // insert whitespace before CAPs and trim off the leading and trailing spaces
        errFields.push(k.replace(/([A-Z])/g, ' $1').trim());
      }
    }
    if (errFields.length) {
      toast.error(`Please check ${_join(errFields, ', ')} before saving the profile.`);
    }
  }, [errors]);

  const userType = watch('UserType');
  const complianceDateFields = useSelector(selectComplianceDateFieldNames);
  const lifeDateFields = useSelector(selectLifeDateFieldNames);
  const accountIntegrations: IAccountIntegration[] = useSelector(getAccountIntegrations);
  const cancelLink = '/profiles/';
  const discardChange = (): void => history.goBack();

  const addingFamily = useSelector((state: RootState) => state.Profiles.addingFamily);
  const addFamilyParameter = () => {
    dispatch(profilesAddFamilyStarted(data?.CustomerProfileID))
  }
  const languageList: ILanguage[] = useSelector((state: RootState) => state.UserInfo.userInfo?.Languages);
  const [LANGUAGES, setLANGUAGES] = useState<IOptions[]>([]);
  useEffect(() => {
    const arrObj = []
    languageList?.forEach((val) => {
      arrObj.push({
        label: val.DisplayName,
        value: val.Value,
      })
    });
    setLANGUAGES(arrObj);
  }, [languageList]);

  const translateUserType = useUserTypeTranslation();
  // This capitalization is needed - display user type integration on profile detail page.
  const formatUserType = (ut: string) => _capitalize(translateUserType(ut));

  const profileTypes = useSelector(
    (state: RootState) => state.UserInfo.userInfo?.AccountDetail.UserTypeMappings);

  const userTypeOptions: IOptions[] = useMemo(() => {
    const options: IOptions[] = [];
    if (profileTypes) {
      Object.entries(profileTypes).forEach(([key, val]) => {
        options.push({ label: val, value: key });
      })
    }
    return options;
  }, [profileTypes])

  const familyTypeOptions: IOptions[] = useMemo(() => {
    const options: IOptions[] = [];
    options.push({ label: translateUserType('staff'), value: 'Staff' });
    options.push({ label: translateUserType('family'), value: 'Family' });
    return options;
  }, [translateUserType]);

  const syncedFamilyTypeOptions: IOptions[] = useMemo(() => {
    const options: IOptions[] = [];
    options.push({ label: translateUserType('family'), value: 'Family' });
    return options;
  }, [translateUserType]);

  const renderCloseButton = (): ReactElement | undefined => {
    if (addingFamily?.customerProfileID) {
      return (
        <>
          <button type="button" className="btn btn-secondary" onClick={() => setShowModal(true)}>Discard</button>
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            className="DiscardChangeModal"
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton={!interactionDisabled} />
            <Modal.Body>
              <FontAwesomeIcon
                icon="trash-alt"
                size="4x"
                color="#FF7052"
              />

              <div className="message">
                <h4>Are you sure?</h4>
                <p>
                  This action will discard all unsaved changes.
                </p>
              </div>

              <button
                className="btn btn-block btn-primary"
                disabled={interactionDisabled}
                onClick={discardChange}
                type="button"
              >
                Discard
              </button>
              <button
                className="btn btn-block btn-secondary"
                disabled={interactionDisabled}
                onClick={() => setShowModal(false)}
                type="button"
              >
                Cancel
              </button>
            </Modal.Body>
          </Modal>
        </>
      );
    }
    return null;
  };

  const interceptSubmit = (formData: IFormData): void => {
    onSubmit({
      ...formData,
      Groups: associatedGroups,
    });
  };
  useEffect(() => {
    (async function fetchFamily() {
      if (data && data?.RelatedProfiles?.length > 0) {
        await dispatch(getProfileFamiliesByCpids(data?.RelatedProfiles));
      }
    }());
  }, [data, dispatch]);

  const getRowColor = (index: number): string => {
    if (index % 2 === 1) {
      return 'oddGroupItem selectedProfileItem';
    }
    return 'evenGroupItem selectedProfileItem';
  }
  const renderFamilyTable = (): ReactElement | undefined => {
    if (addingFamily) {
      return (
        <>
          <DeleteActionModal
            isOpen={familyIdToDelete !== undefined}
            onCancel={() => {
              setFamilyIdToDelete(undefined)
            }}
            onSuccess={() => {
              dispatch(putProfileRelationships(data?.CustomerProfileID,
                data?.RelatedProfiles.filter((cpid) => cpid !== familyIdToDelete),
                true));
              dispatch(profilesAddFamilySuccess());
              setFamilyIdToDelete(undefined);
            }}
            title="Delete?"
          >
            Are you sure you wish to delete this relationship?
          </DeleteActionModal>
          {profilesLoading && (
            <Container>
              <Row>
                <Col className="align-middle mt-2 ml-1">
                  Loading...
                </Col>
              </Row>
            </Container>
          )}

          {!profilesLoading && (
            data?.RelatedProfiles?.map((id, index) => (
              <div
                key={allProfilesByIds[id]?.CustomerProfileID}
                className="no-overflow-x cursor-pointer"
              >
                <Container fluid>
                  <Row
                    className={getRowColor(index)}
                  >
                    <Col xs={8} className="align-middle mt-2 ml-1">
                      {allProfilesByIds[id]?.FirstName}
                      {' '}
                      {allProfilesByIds[id]?.LastName}
                    </Col>
                    <Col className="align-middle mt-1">
                      <Button
                        style={{ padding: '0rem' }}
                        type="button"
                        variant="link"
                        onClick={() => {
                          setFamilyIdToDelete(id);
                        }}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </div>
            ))
          )}
        </>
      );
    }
    return null;
  }
  const getUserTypeOptions = (): IOptions[] => {
    if (addingFamily?.customerProfileID && initialUserType) {
      if (isSyncedCustomer) {
        return syncedFamilyTypeOptions;
      }
      return familyTypeOptions;
    }
    return userTypeOptions;
  };
  const renderAddAndAssociateFamilyMemberLink = (): ReactElement | undefined => {
    if (isNew) return null;
    if (isSyncedCustomer && userType !== UserType.Seniors) return null;
    return (
      <>
        <div>
          <Link
            to="/profiles/new?UserType=Family"
            onClick={() => {
              addFamilyParameter();
              document.querySelector('.Main').scroll({ top: 0, left: 0, behavior: 'smooth' });
            }}
          >
            <FontAwesomeIcon icon="plus" />
            {' '}
            Add&nbsp;
            {formatUserType('family')}
            &nbsp;
            {formatUserType('family') === 'Family' ? 'Member' : ''}
          </Link>
        &nbsp;|&nbsp;
          <a
            href={window.location.pathname}
            onClick={(e) => {
              setAssociationModalOpen(true);
              e.preventDefault();
            }}
          >
            Associate
            &nbsp;
            {formatUserType('family')}
            &nbsp;
            {formatUserType('family') === 'Family' ? 'Member' : ''}
          </a>
        </div>
        <div>
          {renderFamilyTable()}
        </div>
      </>
    );
  };

  return (
    <>
      <Prompt
        when={isBlocking}
        message={JSON.stringify(messageObj)}
      />
      <form
        onSubmit={handleSubmit(interceptSubmit)}
        className="form ProfilesForm"
      >
        <FormProvider {...formMethods}>
          <div className="header-bar">
            <h1>
              {isNew ? 'New' : 'Edit'}
              {' '}
              Profile
            </h1>
            <div className="action-bar">
              <div>
                <button
                  type="button"
                  role="link"
                  className="btn btn-link"
                  onClick={() => {
                    history.push(saving ? '#' : cancelLink)
                  }}
                >
                  Cancel
                </button>
                <SubmitButton
                  label="Save"
                  savingLabel="Saving..."
                  className="btn btn-primary"
                  saveComplete={!saving}
                />
              </div>
            </div>
          </div>
          <div className="account-integration">
            {(() => {
              if (accountIntegrations && userType) {
                const integration = accountIntegrations.find(
                  (ai) => ai.UserType.toLowerCase() === userType.toLowerCase()
                  || (userType === UserType.Seniors && ai.UserType.toLowerCase() === 'seniors'),
                );
                if (integration) {
                  return (
                    <Alert variant="info" key={userType}>
                      {`${formatUserType(userType)
                      } records are managed by ${integration.IntegrationPartner
                      }. Any changes will be overwritten.`}

                    </Alert>
                  );
                }
              }
              return null;
            })()}
          </div>
          <span className="account-integration m-2">
            {!isNew && userType === UserType.Family && caremergeUserID === 0 && isSyncedCustomer
            && (
              <Alert variant="info">
                This profile is not currently synced with caremerge.
                To sync with caremerge, add a relationship to a
                &nbsp;
                {formatUserType('senior')}
                &nbsp;
                {formatUserType('senior') === 'Senior' ? 'member ' : ' '}
                using the controls below.
              </Alert>
            )}
          </span>
          <fieldset>
            <legend>Personal Information</legend>
            <div className="row">
              <div className="col-xs-12 col-md">
                <Input
                  id="FirstName"
                  name="FirstName"
                  label="First Name"
                  type="text"
                  control={control}
                  errors={errors.FirstName && 'First Name is required'}
                  rules={{ required: true }}
                />
              </div>
              <div className="col-xs-12 col-md">
                <Input
                  id="LastName"
                  name="LastName"
                  label="Last Name"
                  type="text"
                  control={control}
                  errors={errors.LastName && 'Last Name is required'}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <Select
                  id="UserType"
                  name="UserType"
                  label="Type"
                  defaultValue={initialUserType}
                  placeholder="Select type"
                  control={control}
                  disabled={!isNew && isSyncedCustomer}
                  options={getUserTypeOptions()}
                  errors={errors.UserType && 'Type is required'}
                  rules={{ required: true }}
                />
              </div>
              <div className="col-xs-12 col-md-6">
                <Select
                  id="Language"
                  name="Language"
                  label="Language"
                  placeholder="Select language"
                  defaultValue={LanguageNames.English}
                  control={control}
                  options={LANGUAGES}
                  errors={errors.Language && 'Language is required'}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md">
                {userType !== 'Staff' && (
                  <Input
                    id="RoomNumber"
                    name="RoomNumber"
                    label="Room Number"
                    type="text"
                    control={control}
                  />
                )}
                {userType === UserType.Staff && (
                  <Input
                    id="JobClassification"
                    name="JobClassification"
                    label="Job Classification"
                    type="text"
                    control={control}
                    maxLength={64}
                  />
                )}
              </div>
              <div className="col-xs-12 col-md">
                {userType === UserType.Staff && (
                  <Input
                    id="Role"
                    name="Role"
                    label="Role"
                    type="text"
                    control={control}
                    maxLength={64}
                  />
                )}
              </div>
            </div>
            <CustomFields
              accountCustomFields={accountCustomFields}
              control={control}
              data={data}
              userType={userType}
            />
          </fieldset>

          <GroupManager
            groups={associatedGroups}
            onAssociateGroup={(groupIds) => setAssociatedGroups(groupIds)}
            caremergeUserId={caremergeUserID}
            userType={userType}
          />

          <fieldset>
            <legend>Contact Information</legend>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  id="LandLine"
                  name="LandLine"
                  label="Landline Phone"
                  type="text"
                  mask="(999) 999-9999"
                  control={control}
                  errors={errors?.LandLine && 'Invalid Phone Number'}
                  rules={{
                    required: false,
                    validate: (val: any) => {
                      if (val === null || val === undefined || val === '' || val === placeHolder) {
                        return true;
                      }
                      return phoneRegEx.test(val);
                    },
                  }}
                />
                <Input
                  id="MobilePhone"
                  name="MobilePhone"
                  label="Mobile Phone"
                  type="text"
                  mask="(999) 999-9999"
                  control={control}
                  errors={errors?.MobilePhone && 'Invalid Phone Number'}
                  rules={{
                    required: false,
                    validate: (val: any) => {
                      if (val === null || val === undefined || val === '' || val === placeHolder) {
                        return true;
                      }
                      return phoneRegEx.test(val);
                    },
                  }}
                />
              </div>
              <div className="col-12 col-md-6">
                <Input
                  id="Email"
                  name="Email"
                  label="Email Address"
                  type="email"
                  control={control}
                />
                <Input
                  id="OnDemand"
                  name="OnDemand"
                  label="On Demand Caller ID"
                  type="text"
                  mask="(999) 999-9999"
                  control={control}
                  errors={errors?.OnDemand && 'Invalid Phone Number'}
                  rules={{
                    required: false,
                    validate: (val: any) => {
                      if (val === null || val === undefined || val === '' || val === placeHolder) {
                        return true;
                      }
                      return phoneRegEx.test(val);
                    },
                  }}
                />
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Communications</legend>
            <div className="row">
              <div className="col col-md-6">
                <div>
                  Do not call between:
                  <div className="input-group">
                    <TimeInput
                      id="BlockBeginTime"
                      name="BlockBeginTime"
                      label="Start Time"
                      control={control}
                    />
                    <TimeInput
                      id="BlockEndTime"
                      name="BlockEndTime"
                      label="End Time"
                      control={control}
                    />
                  </div>
                </div>

                <InactivePeriodDates />

              </div>
              <div className="col col-md-6">
                <div>Communication Preference</div>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <Checkbox
                      name="LandLineOptIn"
                      label="Landline Voice"
                      ref={register}
                    />
                    <Checkbox
                      name="MobilePhoneOptIn"
                      label="Mobile Voice"
                      ref={register}
                    />
                    <Checkbox
                      name="SMSOptIn"
                      label="Text Message"
                      ref={register}
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <Checkbox name="EmailOptIn" label="Email" ref={register} />
                    <Checkbox
                      name="OnDemandOptIn"
                      label="On-Demand"
                      ref={register}
                    />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset className="compliance">
            <legend>Events</legend>
            <div className="row">
              <FieldMap
                hide={userType !== 'Staff'}
                fieldMap={complianceDateFields}
                fieldClassName="col col-md-6"
                type="date"
                control={control}
                errors={errors}
              />
            </div>
            <div className="row">
              <FieldMap
                fieldMap={lifeDateFields}
                fieldClassName="col col-md-6"
                type="date"
                control={control}
                errors={errors}
              />
            </div>
          </fieldset>

          <fieldset>
            <legend>Address</legend>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <Input
                  id="Address"
                  name="Address"
                  label="Address"
                  type="text"
                  control={control}
                />
                <Input id="City" name="City" label="City" type="text" control={control} />
              </div>
              <div className="col-xs-12 col-md-6">
                <Select
                  id="State"
                  name="State"
                  label="State"
                  placeholder="Select state"
                  control={control}
                  options={US_STATES}
                />
                <Input
                  id="ZipCode"
                  name="ZipCode"
                  label="Zip Code"
                  type="text"
                  control={control}
                  maxLength={20}
                />
              </div>
            </div>
          </fieldset>

          <div className="action-bar pt-5">
            <div>
              {renderAddAndAssociateFamilyMemberLink()}
            </div>
          </div>
          <div className="float-right">
            {renderCloseButton()}
            <Link className="btn btn-secondary" to={saving ? '#' : cancelLink}>
              Cancel
            </Link>
            <SubmitButton
              style={{ marginLeft: '1rem' }}
              label="Save"
              savingLabel="Saving..."
              className="btn btn-primary"
              saveComplete={!saving}
            />
          </div>
        </FormProvider>
      </form>
      <AssociationModal
        show={associationModalOpen}
        toggle={() => setAssociationModalOpen(!associationModalOpen)}
        familyProfiles={familyProfiles}
        baseProfileId={data?.CustomerProfileID}
      />
    </>
  );
}

export default Form;
